<template>
  <div id="idea-plan" class="shared">

    <div class="container-1200">

      <!-- Loader -->
      <loader v-if="loading" />
      <!-- /Loader -->

      <div v-if="notFound === true" class="content not-found">
        <h1>{{ $t('sidebar.ideaPlan') }}</h1>
        <p class="text-lead">
          {{ $t('notFound') }}
        </p>
      </div>

      <div v-if="loading === false && notFound === false" class="content">
        <!-- Idea Plan Header -->
        <div class="header-flex">

          <!-- Title -->
          <div class="edit-wrapper">
            <h2 class="edit-element">
              {{ ideaPlan.title }}
            </h2>
          </div>
          <!-- /Title -->

          <!-- Action Buttons -->
          <div class="action-buttons">
            <ib-tooltip
              popper-class="small center"
              :content="$t('export')"
            >
              <el-dropdown class="action-items" trigger="click" @command="generateDocument">
                <a class="download el-dropdown-link print" @click.prevent>
                  <i class="ib-icon-download h3 text-silver hover-scale" />
                </a>
                <el-dropdown-menu slot="dropdown" class="export-dropdown-menu">
                  <el-dropdown-item class="d-flex" icon="ib-icon-export-pdf" command="pdf">
                    {{ $t('exportToPdf') }}
                  </el-dropdown-item>
                  <el-dropdown-item class="d-flex" icon="ib-icon-export-word" command="doc">
                    {{ $t('exportToWord') }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </ib-tooltip>
          </div>
          <!-- /Action Buttons -->

        </div>
        <!-- /Idea Plan Header -->

        <component :is="layout" :data="ideaPlan.sections" />

      </div>
    </div>

    <!-- Dialog Export/Generate -->
    <dialog-generate
      :api="'/idea-plan/generate/' + ideaPlan.id"
      :format="generateFormat"
      :dialog-visible="generateDialog"
      @close="generateDialog = false"
    />
    <!-- /Dialog Export/Generate -->
  </div>
</template>
<script>
import DialogGenerate from '@/views/Home/StoryMode/Components/Dialogs/DialogGenerate'
import IdeaPlanLayout from '@/views/Home/IdeaPlan/Section/IdeaPlanLayout'
import Loader from '@/components/Loader'

export default {
  name: 'IdeaPlanShared',

  components: {
    IdeaPlanLayout,
    Loader,
    DialogGenerate
  },

  data () {
    return {
      layout: 'IdeaPlanLayout',
      ideaPlan: {
        title: '',
        sections: [],
        ideaId: this.$store.state.idea.id
      },
      loading: true,
      notFound: false,
      generateDialog: false,
      generateFormat: ''
    }
  },

  mounted () {
    this.getIdeaPlan()
  },

  methods: {
    getIdeaPlan () {
      this.loading = true
      this.$http.get(
        'idea-plan/shared',
        { params: { token: this.$route.params.token } }
      ).then(response => {
        this.ideaPlan = response.data.payload.ideaPlan
      }).catch(() => {
        this.notFound = true
      }).finally(() => {
        this.loading = false
      })
    },

    generateDocument (format) {
      this.generateFormat = format
      this.generateDialog = true
    }
  }
}
</script>

<style>
  #idea-plan {
    margin-left: 0;
  }

  #idea-plan .content .masonry {
    margin-bottom: 0;
    padding-bottom: 80px;
  }

  .masonry .item .el-button--plus-round-xs,
  .masonry .note .edit-bottom {
    display: none !important;
  }

  #idea-plan .content.not-found {
    min-height: 100vh;
  }
  #idea-plan .content.not-found {
    min-height: 100vh;
  }
  #idea-plan .content.not-found h1,
  #idea-plan .content.not-found p {
    margin: 0;
    padding: 0;
  }
  #idea-plan .content.not-found p {
    opacity: 0.5;
  }
</style>
